














import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import TableBase from '@/components/atoms/TableBase.vue'
import ModalConditionExit from '@/components/organisms/v3/mode/ModalConditionExit.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { ConditionExit, ConditionItem, Subject } from '@/types/teacher/mode'
import _ from 'lodash'

@Component({
  components: {
    TableBase,
    ModalConditionExit,
    PopupBase,
    ButtonBase,
  },
})
export default class LearningEndConditionPopup extends Vue {
  @Prop()
  show?: boolean
  @Prop()
  isModeDefault!: boolean
  @Prop()
  subjects!: Subject[]
  @Prop()
  dataInit!: { type: 1 | 2; data: ConditionExit[] }
  @Prop()
  modeId!: number
  @Prop()
  handleClose?: () => void
  @Prop()
  private dataUpdate!: any

  @Prop()
  onSubmit!: (type: number, data: ConditionExit[], isChange?: boolean) => void

  private itemOptionDatas: { value?: number; text?: string; unit?: string }[] = []

  private tableBaseChallengeItems: ConditionItem[] = []

  private restrictionDefault: { value?: number; text?: string; unit?: string } = {}

  private tableBaseFields = [
    { key: 'subject', label: '教科' },
    { key: 'item', label: '制限項目' },
    { key: 'limit', label: '制限しきい値' },
    { key: 'action', label: '' },
  ]

  private tableBaseButtons = ['action']

  @Ref()
  modalCondition!: ModalConditionExit

  private closeEvent() {
    this.fillData()
    this.$emit('handle-close')
  }

  private showDefaultExitCondition(number: number, index: number): void {
    const item: any = this.tableBaseChallengeItems[index]
    this.modalCondition.setDefaultExitCondition(item.subjectId, item.subject, item.itemId, item.limitThreshold)
    this.modalCondition.show()
  }

  private mounted() {
    this.loadRestrictions()
  }

  private get mapSubject() {
    const map = this.subjects.reduce((result, currentItem) => {
      result[currentItem.subjectId] = currentItem.name
      return result
    }, {})
    return map
  }

  private get mapRestriction() {
    const map = this.itemOptionDatas.reduce((result, currentItem) => {
      result[currentItem.value || 0] = {
        name: currentItem.text,
        unit: currentItem.unit,
      }
      return result
    }, {})
    return map
  }
  private get mapRestrictionUnit() {
    const map = this.itemOptionDatas.reduce((result, currentItem) => {
      result[currentItem.unit || 0] = {
        name: currentItem.text,
        id: currentItem.value,
      }
      return result
    }, {})
    return map
  }

  @Watch('dataUpdate', { deep: true })
  watchDataUpdate() {
    this.tableBaseChallengeItems = this.fillDataUpdate(this.tableBaseChallengeItems)
    this.submitData(true)
  }

  private fillDataUpdate(tableData: ConditionItem[]) {
    const dataCondition = this.dataUpdate.learning_end_condition
    return tableData.map((item) => {
      const dataSubject = dataCondition.data[item.subject || '']
      const limit = dataSubject.limitThreshold + dataSubject.restriction
      return {
        ...item,
        limitThreshold: dataSubject.limitThreshold,
        unit: dataSubject.restriction,
        item: this.mapRestrictionUnit[dataSubject.restriction || '']?.name,
        limit,
        itemId: this.mapRestrictionUnit[dataSubject.restriction || '']?.id,
      }
    })
  }

  @Watch('dataInit', { deep: true })
  watchDataInit() {
    this.fillData()
  }

  private submitData(isChange = false) {
    const data = this.tableBaseChallengeItems
    const dataSubmit = data.map((item) => ({
      subjectId: item.subjectId,
      subjectName: item.subject,
      restrictionId: item.itemId,
      restrictionName: item.item,
      restrictionUnit: item.unit,
      limitThreshold: +item.limitThreshold,
    }))
    this.onSubmit(1, dataSubmit, isChange)
    this.$emit('handle-close')
  }

  private numberDefault(name: string) {
    if (name === '国語') return 1
    return 5
  }

  private updateDatas(data: {
    subjectId: number
    restrictionId: number
    limitThreshold: number
    unit: string
    restrictionName: string
  }) {
    const endConditions = [...this.tableBaseChallengeItems]
    const _data: any = endConditions.find((item) => item.subjectId == data.subjectId) || {}
    _data.limitThreshold = data.limitThreshold
    _data.limit = data.limitThreshold + data.unit
    _data.item = data.restrictionName
    _data.itemId = data.restrictionId
    _data.unit = data.unit
  }

  private fillData() {
    if (this.subjects.length === 0 && this.dataInit.data.length === 0) return
    const endConditions = this.dataInit.data.map((endCondition) => {
      const limit =
        (endCondition.limitThreshold || 0) + (this.mapRestriction[endCondition.restrictionId || 0]?.unit || '')
      return {
        subjectId: endCondition.subjectId,
        subject: this.mapSubject[endCondition.subjectId || 0],
        item: this.mapRestriction[endCondition.restrictionId || 0]?.name,
        limit,
        action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
        itemId: endCondition.restrictionId,
        limitThreshold: endCondition.limitThreshold,
        unit: this.mapRestriction[endCondition.restrictionId || 0]?.unit,
      }
    }) as ConditionItem[]
    if (this.dataInit.type == 1) {
      this.tableBaseChallengeItems = endConditions
    }
  }

  private async loadRestrictions() {
    await Vue.prototype.$http.httpWithToken.get('/restrictions').then((res: any) => {
      this.itemOptionDatas =
        res.data.restrictions.map((restriction: { id: number; name: string; unit: string }) => {
          return {
            value: restriction.id,
            text: restriction.name,
            unit: restriction.unit,
          }
        }) || []
      this.restrictionDefault = this.itemOptionDatas?.find((item) => item.unit === '問') || {}
      this.loadDatas()
    })
  }

  public loadDatas() {
    const conditions: ConditionItem[] = this.subjects.map((subject) => {
      return {
        subjectId: +subject.subjectId,
        subject: subject.name,
        item: this.restrictionDefault.text,
        limit: this.numberDefault(subject.name || '') + (this.restrictionDefault?.unit || ''),
        action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
        itemId: this.restrictionDefault.value,
        limitThreshold: this.numberDefault(subject.name || ''),
        unit: this.restrictionDefault.unit,
      } as ConditionItem
    })
    this.tableBaseChallengeItems = _.cloneDeep(conditions)
    if (this.dataInit?.data?.length > 0) {
      this.fillData()
      return
    }
    this.submitData()
  }
}
