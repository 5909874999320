








import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Suspense extends Vue {
  @Prop()
  isLoading!: boolean
}
